<template>
  <div class="managePlatform">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">临床医学研究管理平台</h6>
        <div class="line"></div>
        <p class="banner_content">CLINICAL MEDICAL RESEARCH MANAGEMENT PLATFORM</p>
      </div>
    </div>
    <div class="manage">
      <img class="mobile-img" src="../assets/platformImages/platfrom_02.jpg" alt="">
      <div class="manage_content">
        <p class="manage_content_top">
          传统临床数 据管理现状
        </p>
        <div class="manage_text">
          <p class="number">01</p>
          <p class="manage_title">数据标准低 效及不规范</p>
          <p class="manage_con">传统临床数据管理中，缺乏统一的数据标准和格式，导致不同系统之间数据不一致，不易进行数据整合和分析。</p>
        </div>
        <div class="manage_text">
          <p class="number pc-number">02</p>
          <p class="manage_title">纸质数据难以保存</p>
          <p class="manage_con">许多医疗机构和临床研究团队仍然依赖于纸质记录来收集和记录患者的临床数据。这种方式存在着数据可读性差、易于丢失或损坏等问题。</p>
        </div>
        <div class="manage_text">
          <p class="number">03</p>
          <p class="manage_title">数据管理成本高</p>
          <p class="manage_con">手工录入和纸质记录容易导致数据输入错误，同时也需要更多的时间和努力来整理和分析数据。</p>
        </div>
        <div class="manage_text text ">
          <p class="number pc-number">04</p>
          <p class="manage_title">数据核查滞后</p>
          <p class="manage_con">采用纸质记录或局部电子系统，数据分散存储，导致数据整合与核查变得复杂和耗时。</p>
        </div>

      </div>

    </div>
    <img class="mobile-img" src="../assets/platformImages/platfrom.jpg" alt="">
    
    <div class="platform">
      <div class="container">
        <div class="top">
          <h6 style="font-size: 32px;color: #999999;">OPEN MANAGEMENT PLATFORM FOR CLINICAL MEDICAL RESEARCH</h6>
          <p style="font-size:30px;color: #010101;">开放的临床医学研究管理平台</p>
          <div class="top_line"></div>
        </div>
        <div>
          医学团队与IT团队联合开发，以数据分析为目标，逆向设计整个数据库结构，实现CRF的动态设计数据多平台录入及导出，切实满足临床医生的科研需求。
        </div>
        <div class="list">
          <div v-for="item in 2" :key="item" class="inventory">
            临床医学研究开放管理平台
          </div>
          <div>
            <div v-for="(item, id) in list1" :key="id" class="left">
              <li>{{ item.listItem }}</li>
            </div>
          </div>
          <div>
            <div v-for="(item, index) in list2" :key="index" class="right">
              <li>{{ item.listItem }}</li>
            </div>
            <div class="right">
            </div>
            <div class="right">
            </div>
          </div>
          <img src="../assets/platformImages/VS.png" alt="" style="position: absolute;top: 30%;left: 36%;">
        </div>
      </div>

    </div>
    <div class="process">
      <div class="top">
        <h6 class="top_text">PLATFORM ENTRY PROCESS</h6>
        <p class="top_content">平台录入流程</p>
        <div class="top_line"></div>
      </div>
      <div class="process_pic pc-img">
        <el-carousel :autoplay="true" :interval="3000" layout="prev, pager, next" height="535px">
          <el-carousel-item>
            <img src="../assets/platformImages/process4.jpg" alt=""
              style="width: 732px;height:490px;border: 1px dashed rgb(148, 145, 145);">
            <img src="../assets/platformImages/process5.jpg" alt=""
              style="width: 732px;height:490px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>

            <img src="../assets/platformImages/process6.jpg" alt=""
              style="width: 732px;height:490px;border: 1px dashed rgb(148, 145, 145);">
            <img src="../assets/platformImages/process7.jpg" alt=""
              style="width: 732px;height:490px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/platformImages/process8.jpg" alt=""
              style="width: 732px;height:490px;border: 1px dashed rgb(148, 145, 145);">
            <img src="../assets/platformImages/process9.jpg" alt=""
              style="width: 732px;height:490px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="process_pic mobile-img">
        <el-carousel :autoplay="true" :interval="3000" layout="prev, pager, next">
          <el-carousel-item>
            <img src="../assets/platformImages/process4.jpg" alt=""
              style="width: 361px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/platformImages/process5.jpg" alt=""
              style="width: 361px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/platformImages/process6.jpg" alt=""
              style="width: 361px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/platformImages/process7.jpg" alt=""
              style="width: 361px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/platformImages/process8.jpg" alt=""
              style="width: 361px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/platformImages/process9.jpg" alt=""
              style="width: 361px;border: 1px dashed rgb(148, 145, 145);">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="process_bottom">
      <div class="top">
        <h6 class="top_text">PLATFORM SERVICE MODE</h6>
        <p class="top_content">平台服务模式</p>
        <div class="top_line"></div>
      </div>
      <div class="container">
        <div class="mode">
          <div class="mode_title"> 01 免费录入</div>
          <div class="mode_article">
            注册即免费使用(1个项目/200病例数)，数据免费导出;升级VIP后即可获得5个项目/1000病例数，外加200张检查单OCR识别额度，同时拥有专人指导建库等服务。也可单独购买病例数或项目额度(每个新购项目包含病例额度200例)。
          </div>
        </div>
        <div class="mode">
          <div class="mode_title"> 02 委托数据录入及稽查</div>
          <div class="mode_article">提供多种录入方式，客户可根据实际情况自由选择;数据录入后，鸿创医学专业稽查审核团队将进行严格筛查，确保专业性和有效性，同时，严格的保密制度规范，避免信息泄露。</div>
        </div>
        <div class="mode">
          <div class="mode_title">03 委托CRF表单设计及建库</div>
          <div class="mode_article">根据客户需求、科研方向，为客户提供建议并设计CRF表单，根据客户意见不断修改完善，以确保达到客户要求并验收合格。</div>
        </div>
        <div class="mode">
          <div class="mode_title">04 委托临床科研数据库建设</div>
          <div class="mode_article">可为临床科室搭建部署至本地科研管理系统，与院内HIS、LIS等系统对接，具有数据可视化、多平台数据整合、慢病管理、患者随访等功能特性，加强科室科研管理及患者管理能力。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list1: [
        { listItem: '个人注册,即可免费试用' },
        { listItem: '图像原始资料上传' },
        { listItem: '自带OCR识别技术' },
        { listItem: '动态设计CRF表单' },
        { listItem: '平台提供数据录入及整理服务' },
        { listItem: '云端部署，多平台联动 (PC/小程序)' },
        { listItem: '强大医学统计团队，完善的方案设计及成果转化服务' },
      ],
      list2: [
        { listItem: '根据项目定制开发，费用昂贵' },
        { listItem: '定制化平台，修改手续繁琐' },
        { listItem: '数据仍需要人工录入核对' },
        { listItem: '本地部署，无法联动' },
        { listItem: '只涉及数据处理，缺少转化' }
      ]
    }
  },
}


</script>
<style scoped lang="less">
/* 移除之前的样式 */
/deep/ .el-carousel__indicators {
  text-align: center;
  width: 288px;
}

/deep/ .el-carousel__indicator {
  display: inline-block;
  margin: 0 5px;
}

/deep/ .el-carousel__button {
  background: #908e8e;
  opacity: 1;
}

/deep/ .is-active .el-carousel__button {
  background: #14A5FE;
  opacity: 1;
}

.platform {
  display: block;
  width: auto;
}

.manage_content {
  display: block;
  width: auto;
}

// .mobile-img {
//   display: none;
//   width: 375px;
// }

/* 移动端样式 */
@media screen and (max-width: 767px) {
  .banner {

    width: 100%;
    height: 320px;
    background-image: url(../assets/platformImages/Platform_bannerMobile.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;

      }

    }
  }

  .manage {
    height: 270px;

    // margin-left: 30px;
    .manage_content_top {
      display: none;
      position: absolute;
      top: 100%;
      left: 47%;
      width: 145px;
      height: 61px;
      font-size: 29px;
      color: #0f94ff;
      font-family: AlibabaPuHuiTiB;
      font-weight: bold;
    }

  }

  .platform {
    display: none;
  }

  .manage_content {
    display: none;
  }

  .mobile-img {
    display: block;
    margin: auto;
    width: 360px;
  }

  .top {
    text-align: center !important;
    margin-top: 30px;

    .top_text,
    .top_content {
      font-size: 22px;
    }

    .line,
    .top_line {
      position: relative;
      top: 2%;
      left: 48%;
      width: 30px;
      height: 2px;
      border-radius: 0;
      opacity: 1;
      border: 1px solid black;
      margin: 24px 0;
    }
  }

  .pc-img {
    display: none;
  }

  .mobile-img {
    display: block;
    width: 361px;
    margin: auto;
  }

  .process_pic .el-carousel {
    width: 100%;
    height: auto;
    border: none;
  }

  .process_pic .el-carousel-item {
    text-align: center;
  }

  .process_pic img {
    // width: 80%;
    height: auto;
    border: 1px dashed rgb(148, 145, 145);
    margin: 0 auto;
  }



  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .mode {
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    margin-bottom: 20px;

    .mode_title {
      font-size: 24px;
      color: #0f94ff;
      margin-bottom: 20px;
    }

    .mode_article {
      color: #576F74;
      font-size: 16px;
      border-top: #BFBFBF solid 2px;
      padding-top: 20px;
      width: 100%;
    }
  }

}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .managePlatform {
    height: 3760px;

    .banner {
      width: 100%;
      height: 570px;
      background-image: url(../assets/platformImages/Platform_banner.jpg);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .banner_text {
        width: 521px;
        height: 200px;
        text-align: center;

        .banner_title {
          font-size: 48px;
        }

        .banner_content {
          font-size: 20px;
          color: #333333
        }

        .line {
          position: relative;
          top: 2%;
          left: 44%;
          width: 30px;
          height: 2px;
          border-radius: 0;
          opacity: 1;
          border: 1px solid black;
          margin: 24px 0;
        }
      }
    }

    .manage {
      height: 580px;
      display: flex;
      justify-content: center;
      align-items: center;

      .manage_content {
        position: absolute;
        width: auto;
        display: flex;
        flex-wrap: wrap;
      }

      .manage_content {
        border: 1px dashed black;
        width: 1404px;
        height: 478px;
        background-image: url(../assets/platformImages/manage_bgm.png);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .manage_content_top {
          position: absolute;
          top: 38%;
          left: 46%;
          width: 145px;
          height: 61px;
          font-size: 29px;
          color: #0f94ff;
          font-family: AlibabaPuHuiTiB;
          font-weight: bold;
        }

        .text {
          margin-left: 20px;
        }

        .manage_text {
          margin: 20px 14px;
          width: 562px;
          height: 100px;
          display: flex;

          .number {
            margin: 20px 18px;
            color: #0f94ff;
            font-size: 30px;
            font-family: AlibabaPuHuiTiB;
            font-weight: bold;
          }

          .pc-number {
            margin-left: 120px;
          }

          div:last-child {
            margin-left: 15px;
          }

          .manage_title {
            margin-right: 10px;
            width: 143px;
            height: 54px;
            font-size: 24px;
            color: #3D3E3E;
            font-family: AlibabaPuHuiTiR;
          }

          .manage_con {
            width: 218px;
            height: 100px;
            font-size: 14px;
            color: #75797D;
            font-family: AlibabaPuHuiTiR;
          }
        }
      }
    }

    .pc-img {
      display: block;
      /* 默认显示PC图片 */
      width: auto;
    }

    .mobile-img {
      display: none;
      /* 默认隐藏移动端图片 */

    }

    .platform {
      padding-top: 40px;
      height: 1100px;
      background-color: #E3F3FF;

      .top {
        text-align: center;
      }

      .top_line {
        position: relative;
        top: 2%;
        left: 48%;
        width: 30px;
        height: 2px;
        border-radius: 0;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }

      .list {
        width: 1500px;
        height: 750px;
        display: flex;
        flex-wrap: wrap;
        margin: 20px -160px;
        position: relative;

        .inventory {
          width: 681px;
          height: 82px;
          background-image: url(../assets/platformImages/list.jpg);
          font-size: 24px;
          color: white;
          line-height: 82px;
          margin-left: 20px;
          padding-left: 20px;
        }

        .left,
        .right {
          width: 700px;
          height: 83px;
          line-height: 83px;
          margin: 10px 20px;
          border-radius: 5px;
          padding-left: 20px;
          padding-right: 40px;
        }

        .left {
          width: 700px;
          height: 83px;
          line-height: 83px;
          background: linear-gradient(to right, rgb(255, 255, 255)60%, rgb(#E3F3FF) 90%, );
          margin: 10px 20px;
          padding-left: 20px;
          border-radius: 5px;

          li {
            list-style-type: disc;
          }
        }

        .right {
          text-align: right;
          width: 643px;
          height: 83px;
          line-height: 83px;
          background: linear-gradient(to left, rgb(255, 255, 255)60%, rgb(#E3F3FF) 90%, );
          margin: 10px 20px;
          padding-right: 40px;
          border-radius: 5px;

          li {
            list-style-type: none;
            /* 移除默认的小圆点样式 */
            position: relative;
            /* 设置li元素为相对定位 */
            padding-right: 20px;
          }

          li::before {
            content: "";
            /* 添加伪元素内容 */
            position: absolute;
            /* 设置伪元素为绝对定位 */
            top: 50%;
            /* 将伪元素垂直居中 */
            right: 0px;
            /* 将伪元素置于li元素的右侧 */
            transform: translate(50%, -50%);
            /* 将伪元素水平居中 */
            width: 6px;
            /* 设置伪元素的宽度 */
            height: 6px;
            /* 设置伪元素的高度 */
            background-color: #000;
            /* 设置伪元素的背景颜色，可以根据需要修改 */
            border-radius: 50%;
            /* 设置伪元素的圆角，使其呈现实心圆 */
          }
        }
      }
    }

    .process {
      height: 680px;
      text-align: center;

      .top_line {
        position: relative;
        top: 2%;
        left: 49%;
        width: 30px;
        height: 2px;
        border-radius: 0;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }
    }

    .process_bottom {
      height: 828px;
      background-image: url(../assets/platformImages/process_bottom.jpg);
      text-align: center;
      padding-top: 40px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .top {
        padding-bottom: 40px;
      }

      .top_line {
        position: relative;
        top: 2%;
        left: 49%;
        width: 30px;
        height: 2px;
        border-radius: 0;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }

      .container {
        display: flex;
        flex-wrap: wrap;

        .mode {
          width: 554px;
          height: 300px;
          text-align: left;

          .mode_title {
            font-size: 24px;
            color: #0D80FF;
            margin-bottom: 40px;
          }

          .mode_article {
            color: #576F74;
            font-size: 16px;
            width: 490px;
            border-top: #BFBFBF solid 2px;
            padding-top: 50px;
          }
        }
      }
    }
  }

  .top {
    .top_text {
      font-size: 36px;
      color: #999999;
    }

    .top_content {
      font-size: 30px;
      color: #010101;
    }
  }
}
</style >